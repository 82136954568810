<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Keresés</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Keresés"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <b-overlay :show="loading">
      <vue-good-table
        :columns="columns"
        :rows="getAllLogs"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: quantity -->
          <span v-if="props.column.field === 'quantity'">
            <div style="text-align: center">
              {{ props.row.qty }} {{ props.row.product.unit }}
            </div>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Jelenleg </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                találat a(z) {{ props.total }} -ból / ből
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: "Felhasználó",
          field: "user.name",
          filterOptions: {
            enabled: false,
            placeholder: "Felhasználó keresés",
          },
        },
        {
          label: "Cikkszám",
          field: "product.article_number",
          filterOptions: {
            enabled: false,
            placeholder: "Cikkszám keresés",
          },
        },
        {
          label: "Name",
          field: "product.name",
          filterOptions: {
            enabled: false,
            placeholder: "Név keresés",
          },
        },
        {
          label: "Kitörölt mennyiség",
          field: "quantity",
          filterOptions: {
            enabled: false,
            placeholder: "Kitörölt mennyiség",
          },
        },
        {
          label: "Törlés időpontja",
          field: "created_at",
          filterOptions: {
            enabled: false,
            placeholder: "Mennyiség egység keresés",
          },
        },
      ],
      loading: false,
      searchTerm: "",
    };
  },
  methods: {
    ...mapActions(["fetchLogs"]),
    initLogs() {
      this.loading = true;
      this.fetchLogs().then(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters(["getAllLogs"]),
  },
  created() {
    this.initLogs();
  },
};
</script>
